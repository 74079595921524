import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

const NavStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 5em;

  ul {
    list-style: none;
    padding: 0;
    color: var(--white);
    li a {
      text-decoration: none;
      color: var(--white);
      
      &[aria-current='page'] {
        text-decoration: underline;
        color: var(--white);
      } 
    }
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr auto;
  }
`

export default function Navbar() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          copyright
        }
      }
    }
  `)

  const { title, description } = data.site.siteMetadata
  return (
    <NavStyles>
      <div>
        <Link to='/'>
          <p className="title">{title}</p>
        </Link>
      </div>
      <div>
          <p>.STUDIO</p>
      </div>
    </NavStyles>
  )
}
