import React from 'react'
import 'normalize.css'
import styled from 'styled-components'
import GlobalStyles from '../styles/GlobalStyles'
import Typography from '../styles/Typography'

import Navbar from './Navbar'

const ContentStyles = styled.div`
    padding: 20px;
`;

export default function Layout({ children }) {
    return (
        <>
            <GlobalStyles />
            <Typography />
            <ContentStyles>
                <Navbar/>
                    {children}
            </ContentStyles>
        </>
    )
}
